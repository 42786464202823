import { Box } from "grommet";
import styled from "styled-components";
import BoutiquesLogos from "./boutiquesLogos.js";
import Container from "./container.js";
import * as React from "react";
/* import VideoPopUp from "../components/videoPopUp/video"; */

const Background = styled(Box)`
  margin-bottom: 40px;
  padding-top: 30%;

  @media (min-width: 1200px) {
    margin-bottom: 100px;
    padding-top: 27%;
  }

  @media (min-width: 1600px) {
    padding-top: 14%;
  }

  @media (min-width: 1850px) {
    padding-top: 15%;
  }
`;

const MainGrid = styled.div`
  display: grid;
  grid-gap: 30px;

  @media (min-width: 680px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const VideoTitle = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 55px;
  margin: 0px;

  letter-spacing: -0.03em;
  text-transform: uppercase;

  color: #ffffff;

  @media screen and (min-width: 767px) {
    font-size: 60px;
    line-height: 80px;
  }

  @media screen and (min-width: 991px) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (min-width: 1450px) {
    font-size: 105px;
    line-height: 100px;
  }
`;

const VideoSubTitle = styled.h2`
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.333;
  margin: 0;

  @media screen and (min-width: 767px) {
    font-size: 35px;
  }

  @media screen and (min-width: 991px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1450px) {
    font-size: 54px;
    margin-bottom: 20px;
  }

  @media (min-width: 1600px) {
    color: #012f49;
  }
`;

const LogoWrapper = styled(Box)`
  left: 0;
  right: 0;
  z-index: 10;
`;

const StyledVideoTitle = styled.span`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 55px;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: #8d8057;

  @media screen and (min-width: 767px) {
    font-size: 60px;
    line-height: 80px;
  }

  @media screen and (min-width: 991px) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (min-width: 1450px) {
    font-size: 95px;
    line-height: 100px;
  }
`;

const VideoHero = ({ boutiques }) => {
  return (
    <Box
      pad={{ bottom: "large" }}
      background={{
        color: "#014165",
      }}
    >
      <Background
        background={{
          image: "url(/keyvisualwithouttext.jpg)",
          size: "100% auto",
          position: "top center",
        }}
      >
        <Container style={{ height: "100%" }}>
          <MainGrid>
            <Box alignSelf="center">
              <VideoSubTitle>23.11 - 25.11.2023</VideoSubTitle>
              <VideoTitle>
                Zürich <br />
                Boutiques
                <br />{" "}
                <StyledVideoTitle style={{ letterSpacing: "6%" }}>
                  experiences
                </StyledVideoTitle>
              </VideoTitle>
            </Box>
            {/*             <VideoPopUp /> */}
          </MainGrid>
        </Container>
      </Background>
      <LogoWrapper>
        <Container>
          <BoutiquesLogos white boutiques={boutiques} />
        </Container>
      </LogoWrapper>
    </Box>
  );
};

export default VideoHero;
